// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.align-self-center {
    align-self: 'center';
}

.button-styles {
    margin-bottom: 10px;
    margin-left: 10px;
    color: black;
    background-color: white;
    border: 1px solid black;
}

.container-styles {
    justify-content: space-between;
    display: flex;
    margin-left: 10px;
}

.column-name-styles {
    word-wrap: break-word;
    white-space: normal; 
    max-width: 200px;
    height: '50px';
    vertical-align: top;
}